.layout {
  color: var(--txt-color);
}

.layout__content {
  padding-left: var(--sidebar-width);
  background-color: var(--second-bg);
  min-height: 100vh;
}

@media screen and (max-width: 500px) {
  .layout__content {
    padding-left: 10px;
  }
}

.layout__content-main {
  padding: 30px;
}
