/* .dropdown {
    position: relative;
    z-index: 99;
}

.dropdown__toggle {
    border: 0;
    outline: 0;
    background-color: transparent;
    position: relative;
}

.dropdown__toggle > i {
    font-size: 2.5rem;
    color: var(--txt-color);
}

.dropdown__toggle-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -12px;
    right: -10px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: var(--main-color);
    color: var(--txt-white);
    font-size: 0.8rem;
}

.dropdown__content {
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    width: max-content;
    max-width: 400px;
    background-color: vaR(--main-bg);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    overflow: hidden;
    transform-origin: top right;
    transform: scale(0);
    transition: transform 0.3s ease 0s;
}

.dropdown__content.active {
    transform: scale(1);
    transition: transform 0.5s var(--transition-cubic);
}

.dropdown__footer {
    padding: 20px;
    text-align: center;
} */

.dropdown-menu {
    position: absolute;
    top: 80px;
    padding:10px;
    right: 120px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }
  
  .dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown-menu li {
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-menu li:hover {
    background-color: #f2f2f2;
  }